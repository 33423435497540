import {
  HelicopterDataActionTypes,
  HelicopterDataState,
  SET_CURRENT_HELICOPTER,
  SET_HELICOPTER_LIST,
} from "./types";

const initialState: HelicopterDataState = {
  list: [],
  current: null,
};

export default function helicopterReducer(
  state = initialState,
  action: HelicopterDataActionTypes
): HelicopterDataState {
  switch (action.type) {
    case SET_HELICOPTER_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case SET_CURRENT_HELICOPTER:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
}

// SELECTORS
export const getList = (state: HelicopterDataState) => state.list;
export const getCurrent = (state: HelicopterDataState) => state.current;

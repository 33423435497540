import moment from "moment";

const terminalLocations = [
  //marche
  {
    id: "0",
    name: "Amandola",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 1476ft (SLM)",
    latitude: 42.98,
    longitude: 13.37,
    n: 15,
    approach: "45/225",
  },
  {
    id: "1",
    name: "Ancona",
    coords: "43° 36’ 08” N – 013° 27’ 09” E 146ft (SLM)",
    latitude: 43.6022222,
    longitude: 13.4525,
    n: 12,
    approach: "340/160",
  },
  {
    id: "2",
    name: "Arquata",
    coords: "42° 46’ 40” N – 013° 17’ 38” E 2290ft (SLM)",
    latitude: 42.7728337,
    longitude: 13.0807393,
    n: 10,
    approach: "340/15",
  },
  {
    id: "3",
    name: "Ascoli P.",
    coords: "42° 51’ 36” N – 013° 35’ 78” E 446ft (SLM)",
    latitude: 42.856,
    longitude: 13.596333,
    n: 13,
    approach: "270/90",
  },
  {
    id: "4",
    name: "Belforte",
    coords: "43° 10’ 73” N – 013° 15’ 14” E 817ft (SLM)",
    latitude: 43.178833,
    longitude: 13.252333,
    n: 15,
    approach: "10/190",
  },
  {
    id: "5",
    name: "Cagli",
    coords: "43° 33’ 89” N – 012° 39’ 66” E 790ft (SLM)",
    latitude: 43.564833,
    longitude: 12.661,
    n: 12,
    approach: "247/67",
  },
  {
    id: "6",
    name: "Camerino Ospedale",
    coords: "43° 07’ 73” N – 013° 02’ 98” E 1797ft (SLM)",
    latitude: 43.128833,
    longitude: 13.049667,
    n: 15,
    approach: "139/319",
  },
  {
    id: "7",
    name: "Matelica",
    coords: "43° 16’ 38” N – 012° 59’ 73” E 1068ft (SLM)",
    latitude: 43.273,
    longitude: 12.9955,
    n: 12,
    approach: "310/130",
  },
  {
    id: "8",
    name: "Cingoli",
    coords: "43° 22’ 83” N – 013° 11’ 55” E 1820ft (SLM)",
    latitude: 43.3805,
    longitude: 13.1925,
    n: 15,
    approach: "180/30",
  },
  {
    id: "9",
    name: "Fabriano",
    coords: "43° 20’ 36” N – 012° 49’ 69” E 1324ft (SLM)",
    latitude: 43.339333,
    longitude: 12.828167,
    n: 14,
    approach: "212/32",
  },
  {
    id: "10",
    name: "Fermo",
    coords: "43° 09’ 75” N – 013° 42’ 33” E 763ft (SLM)",
    latitude: 43.1625,
    longitude: 13.7055,
    n: 17,
    approach: "40/220",
  },
  {
    id: "11",
    name: "Fiuminata",
    coords: "43° 11’ 03” N – 012° 56’ 31” E 1470ft (SLM)",
    latitude: 43.183833,
    longitude: 12.9385,
    n: 15,
    approach: "280/100",
  },
  {
    id: "12",
    name: "Mercatello",
    coords: "43° 39’ 07” N – 012° 19’ 74” E 1410ft (SLM)",
    latitude: 43.651167,
    longitude: 12.329,
    n: 16,
    approach: "319/139",
  },
  {
    id: "13",
    name: "Pesaro",
    coords: "43° 54’ 05” N – 012° 52’ 19” E 55ft (SLM)",
    latitude: 43.900833,
    longitude: 12.869833,
    n: 13,
    approach: "203/23",
  },
  {
    id: "14",
    name: "S. Severino",
    coords: "43° 14’ 13” N – 013° 11’ 34” E 787ft (SLM)",
    latitude: 43.2355,
    longitude: 13.189,
    n: 15,
    approach: "265/85",
  },
  {
    id: "15",
    name: "Macerata",
    coords: "43° 17’ 35” N – 013° 27’ 48” E 710ft (SLM)",
    latitude: 43.293245,
    longitude: 13.463324,
    n: 15,
    approach: "80/260",
  },
  {
    id: "16",
    name: "Civitanova M.",
    coords: "43° 18’ 48” N – 013° 41’ 61” E 252ft (SLM)",
    latitude: 43.308,
    longitude: 13.6935,
    n: 15,
    approach: "360/180",
  },
  {
    id: "17",
    name: "Castelraimondo",
    coords: "43° 11’ 98” N – 013° 03’ 28” E 991ft (SLM)",
    latitude: 43.199667,
    longitude: 13.054667,
    n: 15,
    approach: "190/10",
  },
  {
    id: "18",
    name: "S. Ginesio",
    coords: "43° 04’ 77” N – 013° 16’ 86” E 1525ft (SLM)",
    latitude: 43.0880556,
    longitude: 13.290555555555557,
    n: 15,
    approach: "250/70",
  },
  {
    id: "19",
    name: "Camerino Ponte",
    coords: "43° 04’ 74” N – 013° 04’ 57” E 1356ft (SLM)",
    latitude: 43.079,
    longitude: 13.076167,
    n: 15,
    approach: "230/50",
  },
  {
    id: "20",
    name: "Fabriano Ospedale",
    coords: "43° 20’ 48” N – 012° 54’ 32” E 1022ft (SLM)",
    latitude: 43.341333,
    longitude: 12.905333,
    n: 15,
    approach: "190/10",
  },
  {
    id: "21",
    name: "Corridonia",
    coords: "43° 15’ 63” N – 013° 29’ 08” E 357ft (SLM)",
    latitude: 43.2605,
    longitude: 13.484667,
    n: 15,
    approach: "270/90",
  },
  {
    id: "22",
    name: "Fossombrone",
    coords: "43° 41’ 06” N – 012° 48’ 28” E 365ft (SLM)",
    latitude: 43.684333,
    longitude: 12.804667,
    n: 15,
    approach: "260/80",
  },
  {
    id: "23",
    name: "Serravalle",
    coords: "43° 01’ 27” N – 012° 54’ 53” E 2713,25ft (SLM)",
    latitude: 43.0241667,
    longitude: 12.914722222222222,
    n: 15,
    approach: "125/305",
  },
  {
    id: "24",
    name: "Sassocorvaro",
    coords: "43° 46’ 985” N – 012° 29’ 525” E 700ft (SLM)",
    latitude: 43.783083,
    longitude: 12.492083,
    n: 15,
    approach: "20/200",
  },
  {
    id: "25",
    name: "Montegrimano",
    coords: "43° 53’ 8” N – 012° 29’ 11” E 1553ft (SLM)",
    latitude: 43.885667,
    longitude: 12.486333,
    n: 15,
    approach: "300/120",
  },
  {
    id: "26",
    name: "Serra de Conti",
    coords: "43° 33’ 783” N – 013° 1’ 803” E 375ft (SLM)",
    latitude: 43.56305,
    longitude: 13.03005,
    n: 15,
    approach: "245/65",
  },
  {
    id: "27",
    name: "Pergola",
    coords: "43° 34’ 085” N – 012° 49’ 927” E 875ft (SLM)",
    latitude: 43.568083,
    longitude: 12.832117,
    n: 15,
    approach: "258/49",
  },
  {
    id: "28",
    name: "Servigliano",
    coords: "43° 4’ 48” N – 013° 29’ 18” E 770ft (SLM)",
    latitude: 43.074667,
    longitude: 13.486333,
    n: 15,
    approach: "60/240",
  },
  {
    id: "29",
    name: "Comunanza",
    coords: "42° 57’ 00” N – 013° 24 19.8 E 1611ft (SLM)",
    latitude: 42.95,
    longitude: 13.4055,
    n: 15,
    approach: "201/003",
  },
  {
    id: "30",
    name: "Montegallo",
    coords: "42° 50’ 27” N – 013° 19’ 37.2” E 2841ft (SLM)",
    latitude: 42.840833333333336,
    longitude: 13.327,
    n: 15,
    approach: "225/45",
  },
  {
    id: "31",
    name: "Fiastra",
    coords: "43° 02’ 2.4” N – 013° 10’ 9.6” E 2293ft (SLM)",
    latitude: 43.034,
    longitude: 13.169333333333332,
    n: 15,
    approach: "169/330",
  },
  {
    id: "32",
    name: "Visso",
    coords: "42° 56’ 50.4” N – 013° 04’ 58.8” E 2132ft (SLM)",
    latitude: 42.94733333333333,
    longitude: 13.083,
    n: 15,
    approach: "356/176",
  },
  {
    id: "33",
    name: "Carpegna",
    coords: "43° 46’ 46.8” N – 012° 20’ 42” E 2223ft (SLM)",
    latitude: 43.779666666666664,
    longitude: 12.345,
    n: 15,
    approach: "305/125",
  },
  {
    id: "34",
    name: "Apecchio",
    coords: "43° 33 23.4 N – 012° 24’ 46.8” E 1694ft (SLM)",
    latitude: 43.5565,
    longitude: 12.413,
    n: 15,
    approach: "250/70",
  },
  {
    id: "35",
    name: "Urbino",
    coords: "43° 44 39” N – 012° 38’ 9” E 1401ft (SLM)",
    latitude: 43.744166666666665,
    longitude: 12.635833333333332,
    n: 15,
    approach: "348/168",
  },
  //abruzzo
  {
    id: "55",
    name: "L'Aquila",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 42.37948586146261,
    longitude: 13.310127709326741,
    n: 15,
    approach: "",
  },
  {
    id: "56",
    name: "Pietracamela",
    coords: "42° 30’ 1,7” N – 013° 33’ 9,1” E 4624ft (SLM)",
    latitude: 42.50047222,
    longitude: 13.55252778,
    n: 15,
    approach: "",
  },
  {
    id: "57",
    name: "Rocca Di Mezzo",
    coords: "42° 12’ 37” N – 013° 31’ 06” E 4379,921ft (SLM)",
    latitude: 42.2102778,
    longitude: 13.518333333333334,
    n: 15,
    approach: "0/180",
  },
  {
    id: "58",
    name: "Castel Di Sangro",
    coords: "41° 46’ 48” N – 014° 06’ 04” E 2624,267ft (SLM)",
    latitude: 41.78,
    longitude: 14.101111111111111,
    n: 15,
    approach: "",
  },
  {
    id: "59",
    name: "Teramo",
    coords: "42° 40’ 0” N – 013° 43’ 1” E 984,25ft (SLM)",
    latitude: 42.6666667,
    longitude: 13.716944444444444,
    n: 15,
    approach: "30/210",
  },
  //emilia
  {
    id: "71",
    name: "Pavullo",
    coords: "44° 19’ 12 N – 010° 49’ 49.8” E 2244ft (SLM)",
    latitude: 44.32,
    longitude: 10.8305,
    n: 15,
    approach: "200/20",
  },
  {
    id: "72",
    name: "Borgo Val Di Taro",
    coords: "44° 29’ 25.5” N – 009° 45’ 53.339” E 1447ft (SLM)",
    latitude: 44.4904167,
    longitude: 9.764816666666666,
    n: 15,
    approach: "250/020",
  },
  {
    id: "73",
    name: "Castelnuovo Nè Monti",
    coords: "44° 24’ 46.2” N – 010° 24’ 27” E 2388ft (SLM)",
    latitude: 44.4295,
    longitude: 10.4075,
    n: 15,
    approach: "194/044",
  },
  {
    id: "74",
    name: "74",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 42.37948586146261,
    longitude: 13.310127709326741,
    n: 15,
    approach: "",
  },
  {
    id: "75",
    name: "75",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 44.5893616,
    longitude: 10.6563033,
    n: 15,
    approach: "",
  },
  {
    id: "76",
    name: "76",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 44.5893616,
    longitude: 10.6563033,
    n: 15,
    approach: "",
  },
  {
    id: "77",
    name: "77",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 44.5893616,
    longitude: 10.6563033,
    n: 15,
    approach: "",
  },
  {
    id: "78",
    name: "78",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 44.5893616,
    longitude: 10.6563033,
    n: 15,
    approach: "",
  },
  {
    id: "79",
    name: "79",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 44.5893616,
    longitude: 10.6563033,
    n: 15,
    approach: "",
  },
  {
    id: "80",
    name: "80",
    coords: "42° 58’ 48” N – 013° 21’ 75” E 2211,29ft (SLM)",
    latitude: 44.5893616,
    longitude: 10.6563033,
    n: 15,
    approach: "",
  },
];

export class HeliportsModel {
  latitude: number;
  longitude: number;
  att1: string;
  batt2: string;
  batteriesVoltage: string;
  city: string;
  cloudAltitude: number;
  dewPoint: string;
  gps: string;
  gustDirection: string;
  heliLight: boolean;
  humidity: string;
  knot: string;
  pressure: string;
  stat1: string;
  stat2: string;
  status: number;
  temperature: string;
  timestamp: string;
  visibility: number;
  visibilityH: number;
  wind: string;
  windDirection: string;
  windGust: string;
  __v: number;
  _id: string;
  name: string;
  photo: any;
  coords: string;
  approach: string;
  statusColor: string;

  constructor(obj: any) {
    const {
      latitude = null,
      longitude = null,
      att1 = null,
      batt2 = null,
      batteriesVoltage = null,
      city = null,
      cloudAltitude = null,
      dewPoint = null,
      gps = null,
      gustDirection = null,
      humidity = null,
      knot = null,
      pressure = null,
      stat1 = null,
      heliLight = false,
      stat2 = null,
      status = null,
      temperature = null,
      timestamp = null,
      visibility = null,
      visibilityH = null,
      wind = null,
      windDirection = null,
      windGust = null,
      __v = null,
      _id = null,
      name = null,
      photo = null,
      coords = null,
      approach = null,
      statusColor = null,
    } = obj;
    this.latitude = latitude;
    this.longitude = longitude;
    this.att1 = att1;
    this.batt2 = batt2;
    this.batteriesVoltage = batteriesVoltage;
    this.city = city;
    this.cloudAltitude = cloudAltitude;
    this.dewPoint = dewPoint;
    this.gps = gps;
    this.gustDirection = gustDirection;
    this.humidity = humidity;
    this.heliLight = heliLight;
    this.knot = knot;
    this.pressure = pressure;
    this.stat1 = stat1;
    this.stat2 = stat2;
    this.status = status;
    this.temperature = temperature;
    this.timestamp = timestamp;
    this.visibility = visibility;
    this.visibilityH = visibilityH;
    this.wind = wind;
    this.windDirection = windDirection;
    this.windGust = windGust;
    this.__v = __v;
    this._id = _id;
    this.name = name;
    this.photo = photo;
    this.coords = coords;
    this.approach = approach;
    this.statusColor = statusColor;
  }

  public static fromRest(data: any, index?: number) {
    let statusColor =
      moment().diff(moment(data.timestamp), "minutes") > 10
        ? "red"
        : moment().diff(moment(data.timestamp), "minutes") < 5
        ? "green"
        : "yellow";
    let found = terminalLocations.find((x: any) => data.city === x.id);
    return new HeliportsModel({
      ...data,
      name: data.name ? data.name : found ? found.name : "heliports" + index,
      latitude: data.latitude ? data.latitude : found ? found.latitude : 0,
      longitude: data.longitude ? data.longitude : found ? found.longitude : 0,
      coords: data.coords ? data.coords : found ? found.coords : "---------",
      approach: data.approach
        ? data.approach
        : found
        ? found.approach
        : "--/--",
      statusColor,
    });
  }

  public static fromArrayRest(data: any[]) {
    return data.map((x: any, index: number) =>
      HeliportsModel.fromRest(x, index)
    );
  }
}

import data, * as helicopterDataReducer from "./data/reducer";
import { combineReducers } from "redux";
import { HelicopterState } from "./types";

export default combineReducers({
  data,
});

//DATA
export const getListHelicopter = (state: HelicopterState) =>
  helicopterDataReducer.getList(state.data);
export const getCurrentHelicopter = (state: HelicopterState) =>
  helicopterDataReducer.getCurrent(state.data);

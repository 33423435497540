import { combineReducers } from "redux";
import { RootState } from "./types";
import helicopter, * as helicopterReducer from "../helicopter/reducer";
import heliports, * as heliportsReducer from "../heliports/reducer";
import appInfo, * as appInfoReducer from "../appInfo/reducer";

const rootReducer = combineReducers({
  helicopter,
  heliports,
  appInfo,
});

export default rootReducer;

//HELICOPTER
export const getHelicopterList = (state: RootState) =>
  helicopterReducer.getListHelicopter(state.helicopter);
export const getCurrentHelicopter = (state: RootState) =>
  helicopterReducer.getCurrentHelicopter(state.helicopter);

//HELIPORTS
export const getHeliportsList = (state: RootState) =>
  heliportsReducer.getListHeliports(state.heliports);
export const getCurrentHeliports = (state: RootState) =>
  heliportsReducer.getCurrentHeliports(state.heliports);

//APPINFO
export const getUsername = (state: RootState) =>
  appInfoReducer.getUsername(state.appInfo);
export const getPage = (state: RootState) =>
  appInfoReducer.getPage(state.appInfo);
export const getRegion = (state: RootState) =>
  appInfoReducer.getRegion(state.appInfo);

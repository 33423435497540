import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeliportsList } from "../../store/heliports/data/action";
import { getRegion } from "../../store/root/reducer";
import { getAllHelicopter, getAllHeliports } from "../api/api";
import CustomAppBar from "../layout/CustomAppBar";
import DeckMap from "../layout/DeckMap";
import PermanentDrawerLeft from "../layout/PermanentDrawerLeft";
import { setHelicopterList } from "../../store/helicopter/data/action";

export default function HomePage({ handleLogout }: any) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const currentRegion = useSelector(getRegion);
  const [intervalHelicopter, setIntervalHelicopter] = useState<any>(null);

  const logout = () => {
    handleLogout();
    navigate("/", { replace: true });
  };

  const apiHelicopter = () => {
    setIntervalHelicopter(
      setInterval(() => {
        getAllHelicopter()
          .then((x) => dispatch(setHelicopterList(x)))
          .catch((e) => console.error("error ", e));
      }, 30000)
    );
  };

  useEffect(() => {
    if (intervalHelicopter) {
      clearInterval(intervalHelicopter);
      setIntervalHelicopter(null);
    }
    apiHelicopter();
    getAllHelicopter()
      .then((x) => dispatch(setHelicopterList(x)))
      .catch((e) => console.error("error ", e));
    getAllHeliports(currentRegion)
      .then((x) => dispatch(setHeliportsList(x)))
      .catch((e) => console.error("error ", e));
  }, []);

  useEffect(() => () => clearInterval(intervalHelicopter), []);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex w-full z-1 bg-primary">
        <CustomAppBar handleLogout={logout} />
      </div>
      <div className="flex h-full w-full">
        <PermanentDrawerLeft />
        <DeckMap />
      </div>
    </div>
  );
}

import Environment from "../../environment";
import { HelicopterModel } from "../../store/helicopter/data/model";
import { HeliportsModel } from "../../store/heliports/data/model";

const headersLogin: Headers = new Headers({
  "Content-Type": "application/json",
});
const token =
  "f1ec3c625512f6b4fdaeda3f76a0c9cc248b2230f6a049830e0e2b70165c0939e734bb2cfdf34ddd749a6118f0572ddcd8b8fad926136b3e415cb2a906ea824b";

function handleErrors(response: any) {
  if (!response.ok) {
    if (!!!response.statusText) response.json();
    throw new Error(
      response.status
        ? response.status.toString() + " - " + response.statusText
        : response.toString()
    );
  }
  return response;
}

export function authenticate(body: { username: string; password: string }) {
  return fetch(
    Environment.config.url + "login/api/identity/auth/v1.1/authenticate",
    {
      method: "POST",
      headers: headersLogin,
      body: JSON.stringify(body),
    }
  )
    .then((data) => handleErrors(data))
    .then((x) => x.json());
}

export function getAllHeliports(region: string) {
  return fetch(
    Environment.config.url + Environment.config.apiUrl + "live/list",
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
        // token
      }),
    }
  )
    .then((data) => handleErrors(data))
    .then((x) => x.json())
    .then((x) => {
      let data = HeliportsModel.fromArrayRest(x);
      return data.filter((y: any) =>
        region === "marche"
          ? parseInt(y.city) < 50
          : region === "abruzzo"
          ? parseInt(y.city) >= 50 && parseInt(y.city) <= 70
          : region === "emilia romagna"
          ? parseInt(y.city) > 70 && parseInt(y.city) <= 80
          : x
      );
    });
}

export function getDetailHeliport(city: string) {
  return fetch(
    Environment.config.url + Environment.config.apiUrl + "live/id/" + city,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
        // token
      }),
    }
  )
    .then((data) => handleErrors(data))
    .then((x) => x.json())
    .then((x) => HeliportsModel.fromRest(x));
}

export function getPhotosHeliport(city: string, n: number = 1) {
  let nrRand = Math.random();
  return fetch(
    Environment.config.url +
      Environment.config.apiUrl +
      "photo/get?id=" +
      city +
      "&photoId=" +
      n +
      "&rand=" +
      nrRand,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
        // token
        Accept:
          "image/webp,image/avif,video/*;q=0.8,image/jpeg,image/png,image/svg+xml,image/*;q=0.8,*/*;q=0.5",
      }),
    }
  )
    .then((data) => handleErrors(data))
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob));
}

export function getAnalyticsHeliport(
  type: string,
  city: string,
  label: string
) {
  return fetch(
    Environment.config.url +
      Environment.config.apiUrl +
      "analytics/" +
      type +
      "/" +
      city,
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
        // token
      }),
    }
  )
    .then((data) => handleErrors(data))
    .then((x) => x.json())
    .then((x) => {
      let labels: Array<string> = [];
      let data: Array<any> = [];
      x?.forEach((element: any) => {
        labels.push(element.label);
        data.push(element.value);
      });
      return {
        labels: labels.reverse(),
        datasets: [
          {
            label: label,
            data: data.reverse(),
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgb(54, 162, 235)",
          },
        ],
      };
    });
}

export function saveLog(message: string) {
  let body = {
    message,
  };
  return fetch(
    Environment.config.url + Environment.config.apiUrl + "log/save",
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
        // token
      }),
      body: JSON.stringify(body),
    }
  ).then((data) => handleErrors(data));
}

export function functionCamera(city: string, type: object) {
  let body = { city: city, ...type };
  return fetch(
    Environment.config.url + Environment.config.apiUrl + "function/" + city,
    {
      method: "PUT",
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
        // token
      }),
      body: JSON.stringify(body),
    }
  )
    .then((data) => handleErrors(data))
    .then((x) => x.json());
}

export function getAllHelicopter() {
  return fetch(
    // Environment.config.url + Environment.config.apiUrl + "live_heli/list",
    "https://dev.securfly.it/securfly/api/v1/live_heli/list",
    {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem("token") || "",
        // token
      }),
    }
  )
    .then((data) => handleErrors(data))
    .then((x) => x.json())
    .then((x) => HelicopterModel.fromArrayRest(x));
}

export class HelicopterModel {
  _id: string;
  id: number;
  regn: string;
  model: string;
  name: string;
  latitude: number;
  longitude: number;
  event: string;
  speed: string;
  altitude: string;
  transmitted: string;
  received: string;
  __v: number;

  constructor(obj: any) {
    const {
      _id = null,
      id = null,
      regn = null,
      model = null,
      name = null,
      latitude = null,
      longitude = null,
      event = null,
      speed = null,
      altitude = null,
      transmitted = null,
      received = null,
      __v = null,
    } = obj;
    this._id = _id;
    this.id = id;
    this.regn = regn;
    this.model = model;
    this.name = name;
    this.latitude = Number(latitude);
    this.longitude = Number(longitude);
    this.event = event;
    this.speed = speed;
    this.altitude = altitude;
    this.transmitted = transmitted;
    this.received = received;
    this.__v = __v;
  }

  public static fromRest(data: any, index?: number) {
    return new HelicopterModel({
      ...data,
    });
  }

  public static fromArrayRest(data: any[]) {
    return data.map((x: any, index: number) =>
      HelicopterModel.fromRest(x, index)
    );
  }
}

import { HelicopterModel } from "./model";
import {
  HelicopterDataActionTypes,
  SET_CURRENT_HELICOPTER,
  SET_HELICOPTER_LIST,
} from "./types";

export function setHelicopterList(
  list: Array<HelicopterModel>
): HelicopterDataActionTypes {
  return {
    type: SET_HELICOPTER_LIST,
    payload: list,
  };
}

export function setCurrentHelicopter(
  current: HelicopterModel | null
): HelicopterDataActionTypes {
  return {
    type: SET_CURRENT_HELICOPTER,
    payload: current,
  };
}

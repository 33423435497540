import { HelicopterModel } from "./model";

export const SET_HELICOPTER_LIST = "HELICOPTER/DATA/SET_HELICOPTER_LIST";
export const SET_CURRENT_HELICOPTER = "HELICOPTER/DATA/SET_CURRENT_HELICOPTER";

export type HelicopterDataState = {
  list: Array<HelicopterModel>;
  current: HelicopterModel | null;
};

interface SetHelicopterList {
  type: typeof SET_HELICOPTER_LIST;
  payload: Array<HelicopterModel>;
}

interface SetCurrentHeliport {
  type: typeof SET_CURRENT_HELICOPTER;
  payload: HelicopterModel | null;
}

export type HelicopterDataActionTypes = SetHelicopterList | SetCurrentHeliport;
